@import 'styles/mixins';
@import 'styles/variables';

.cx-faq-question-section {
    display: flex;
    flex-direction: column;

    .pb {
        padding-bottom: 10px;
    }

    .collapse-action-container {
        &:hover {
            background-color: $collapse-hover;

            .question-content {
                @include fade(22px, $question-fade-hover-primary, $question-fade-hover-secondary, 76px, 0px);
            }
        }
    }

    .collapse-question {
        background-color: transparent;
        color: $collpase-button;
        cursor: pointer;
        padding: 20px;
        width: 100%;
        border: none;
        text-align: left;
        outline: none;
        font-size: $regularplus;
    }

    .line-clamp-hide {
        -webkit-line-clamp: none;
        -webkit-box-orient: none;
        overflow: visible;
    }

    .line-clamp {
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;

        @media (width > $tablet) {
            max-height: 50px;
            -webkit-line-clamp: 2;
        }
    }

    .question-header-container {
        display: flex;
        align-items: center;
        gap: 24px;

        .question {
            word-break: break-word;
            font-size: $largeplus;
            margin: 0 !important;
            display: -webkit-box;
        }
    }

    .question-content-height-hide {
        max-height: 0px;
    }

    .question-content-height {
        max-height: 450px;
        min-height: 0px;

        @media (width > $tablet) {
            max-height: 620px;
        }
    }

    div[data-simplebar] {
        height: auto;
    }

    .question-content {
        @include fade(22px, $question-fade-primary, $question-fade-secondary, 76px, 0px);
        background-color: transparent;
        position: relative;
        transition: max-height .15s ease;

        .question-answer {
            font-size: $regular;
            color: $collapse-answer;
            word-break: break-word;
            font-weight: 400;
            position: relative;
            margin: 0;
            padding-right: 16px;
            margin-top: 10px;
        }
    }

    .open-icon {
        margin-left: auto;
    }

}