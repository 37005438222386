@use 'styles/variables' as v;
@use 'styles/mixins' as m;

#cx-info-banner {
    @include m.flex();

    .content-container {
        background: v.$secondarycolor-half;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        padding: v.$regularPad;
        flex: 1;
    }

    &:not(.no-stick) .stick {
        width: 3px;
        top: 96px;
        gap: 0;
        opacity: 0.5;
        background: v.$purple-text;
        border-radius: 4px;
    }

    &.no-stick .stick {
        display: none;
    }
}
