@use 'styles/variables' as var;
@use '@chicksgroup/styles/variables' as v-global;
@use '@chicksgroup/styles/mixins' as m-global;

#location-card {

    cds-large-button {
        --cds-large-button-box-min-height: 72px;
    }

    .page-container {
        @include m-global.flex(initial, initial, column, true);
    }

    #page-content-image img {
        all: none !important;
        display: block;
        @include m-global.square(100%);
        object-fit: cover !important;
        object-position: center !important;
        border-radius: 4px;
        max-height: 100% !important;
    }

    @media (max-width: 700px) {
        #page-content-image img {
            @include m-global.square(100%);
        }
    }

    .font-size-inherit {
        margin-bottom: 0px !important;
    }

    .custom-map-modal {
        @include m-global.flex(center, center, row, false);
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        @include m-global.size(100vw, 100vh, false);
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 1000;

        &.visible {
            display: flex;
            opacity: 1;
        }
    }

    .custom-map-modal-content {
        position: relative;
        background: var(--cg-grey-100);
        @include m-global.padding-y(56px, false, true);
        padding-bottom: 16px !important;
        @include m-global.padding-x(24px, false, true);
        display: flex;
        @include m-global.flex(center, center, row, true);
        overflow: hidden;
        @include m-global.size(648px, 516px, true);
        animation: scaleIn 0.3s ease;
        box-shadow: none;
        margin: 0;
        border-radius: 4px;
    }

    .custom-map-iframe {
        @include m-global.size(600px, 440px);
        border: none !important;
    }

    .custom-map-close {
        position: absolute;
        top: -6px;
        right: 8px;
        background: none;
        border: none;
        font-size: 34px;
        color: #333;
        cursor: pointer;
        @include m-global.padding-y(16px, false, true);
        @include m-global.padding-x(16px, false, true);
        line-height: 1;
        z-index: 10;
    }

    #location-content p {
        margin-bottom: 0 !important;
    }

    #location-content {
        @include m-global.flex(column, center, true);
        @include m-global.padding-y(80px, false, true);
        @include m-global.padding-x(20px, false, true);
        margin-top: 60px;
        background: var(--cx-primary-200);
        gap: 40px;

        .header {
            @include m-global.flex(flex-start, space-between, nowrap);
            max-width: 1560px;
            width: calc(100% - 40px);
            margin: 0 auto;
            gap: 80px;

            @media (max-width: 1550px) {
                width: 100%;
            }

            .text-container {
                @include m-global.flex(initial, initial, column, true);
                gap: 0px;

                .hero-txt {
                    @include m-global.font(40px, 700, 48px, 1.6px, var(--cx-secondary-600), null, true);
                    margin-bottom: 12px;
                }

                .hero-content {
                    @include m-global.font(16px, 400, 32px, 0.24px, var(--cg-grey-1000), null, true);
                    align-self: stretch;
                    margin-bottom: 24px !important;
                }

                .address {
                    line-height: 32px;
                    color: var(--cg-grey-1000);
                }

                .btn-container-desktop {
                    @include m-global.flex(initial, flex-start, row);
                    gap: 20px;
                    margin-top: 40px;

                    .primary-style {
                        @include m-global.flex(center, center, row, true);
                        @include m-global.size(248px, 52px, true);
                        @include m-global.padding-x(20px, false, true);
                        border-radius: 4px;
                        text-align: center;
                        @include m-global.font(16px, 500, 20px, 0.04px, var(--cg-grey-100), null, true);
                        background-color: var(--cx-secondary-700);
                        transition: background-color 0.3s ease, color 0.3s ease;

                        &:hover {
                            background: var(--cx-secondary-800);
                        }

                        &:active {
                            background: var(--cx-secondary-900);
                        }

                        &:disabled {
                            background: var(--cx-secondary-700);
                        }

                    }

                    .secondary {
                        @include m-global.flex(center, center, row, true);
                        @include m-global.size(248px, 52px, true);
                        @include m-global.padding-x(20px, false, true);
                        border-radius: 4px;
                        text-align: center !important;
                        @include m-global.font(16px, 500, 20px, 0.04px, var(--cx-secondary-700), null, true);
                        background-color: var(--cx-primary-300);
                        transition: background-color 0.3s ease, color 0.3s ease;

                        &:hover {
                            background: var(--cx-primary-400);
                        }

                        &:active {
                            background: var(--cx-primary-500);
                        }

                        &:disabled {
                            background: var(--cx-primary-600);
                        }

                    }
                }
            }

            .map-container {
                @include m-global.flex(center, center, row, true);
                max-width: 772px;
                height: auto;
                border-radius: 0px;
                background: var(--cx-primary-500);
                overflow: hidden;
                position: relative;
            }
        }

        .btn-container-mobile {
            display: none;

            @media (max-width: 1600px) {
                display: flex;
                gap: 10px;
                width: 100%;

                .btn-mobile-override {
                    @include m-global.flex(initial, flex-start, row, true);
                    gap: 20px;

                    .primary-style {
                        @include m-global.flex(center, center, row, true);
                        @include m-global.size(248px, 52px, true);
                        @include m-global.padding-x(20px, false, true);
                        border-radius: 4px;
                        text-align: center;
                        @include m-global.font(16px, 500, 20px, 0.04px, var(--cg-grey-100), null, true);
                        background-color: var(--cx-secondary-700);
                        transition: background-color 0.3s ease, color 0.3s ease;

                        &:hover {
                            background: var(--cx-secondary-800);
                        }

                        &:active {
                            background: var(--cx-secondary-900);
                        }

                        &:disabled {
                            background: var(--cx-secondary-700);
                        }

                    }

                    .secondary {
                        @include m-global.flex(center, center, row, true);
                        @include m-global.size(248px, 52px, true);
                        @include m-global.padding-x(20px, false, true);
                        border-radius: 4px;
                        text-align: center;
                        @include m-global.font(16px, 500, 20px, 0.04px, var(--cx-secondary-700), null, true);
                        background-color: var(--cx-primary-300);
                        transition: background-color 0.3s ease, color 0.3s ease;

                        &:hover {
                            background: var(--cx-primary-400);
                        }

                        &:active {
                            background: var(--cx-primary-500);
                        }

                        &:disabled {
                            background: var(--cx-primary-600);
                        }
                    }
                }
            }
        }
    }

    #location-content {

        .info-content {
            color: rgba(31, 31, 31, 1);
            .phone-link {
                color: var(--cg-grey-1000);
                text-decoration: none;
                transition: color 0.3s ease, text-decoration 0.3s ease;

                &:hover {
                    text-decoration: underline !important;
                }
            }
        }
    }
}

@media (max-width: 1600px) and (min-width: 1142px) {
    #location-content {

        .header {
            display: flex;
            flex-direction: column !important;
            align-items: center;
            justify-content: flex-start;
            gap: 40px;
            width: 100%;
            max-width: 1200px;
            margin: 0 auto;
        }

        .text-container {
            @include m-global.flex(flex-start, flex-start, column, true);
            flex: 1 1 0%;
            order: 1;
            @include m-global.container-fluid(770px, true);
            text-align: left;
            margin: 0 auto;
            gap: 16px;
        }

        .map-container {
            @include m-global.flex(center, center, row, true);
            order: 2;
            @include m-global.container-fluid(770px, true);
            margin: 0 auto;
        }

        #btn {
            display: none !important;
        }

        .btn-container-mobile-override {
            @include m-global.flex(flex-start, left, row, true);
            @include m-global.container-fluid(770px, true);
            margin-left: auto;
            margin-right: auto;

            .primary-style {
                @include m-global.flex(center, center, row, true);
                @include m-global.size(248px, 52px, true);
                text-align: center;
                background-color: var(--cx-secondary-700);

                &:hover {
                    background: var(--cx-secondary-800);
                }

                &:active {
                    background: var(--cx-secondary-900);
                }

                &:disabled {
                    background: var(--cx-secondary-700);
                }
            }

            .secondary {
                @include m-global.flex(center, center, row, true);
                @include m-global.size(248px, 52px, true);
                text-align: center;
                background-color: var(--cx-primary-300);
                color: var(--cx-secondary-700);

                &:hover {
                    background: var(--cx-primary-400);
                }

                &:active {
                    background: var(--cx-primary-500);
                }

                &:disabled {
                    background: var(--cx-primary-600);
                }
            }
        }
    }
}

@media (max-width: 1142px) and (min-width: 0px) {
    .custom-map-modal-content {
        @include m-global.size(608px, 495px, true);
        max-width: 608px;
        max-height: 495px;
    }

    #location-card #location-content .header .text-container .hero-txt {
        @include m-global.font(32px, 500, 36px, 0.48px, var(--cx-secondary-600), null, true);
    }

    #location-card #location-content .header .text-container .hero-content {
        @include m-global.font(14px, 400, 24px, 0.014px, var(--cg-grey-1000), null, true);
        align-self: stretch;
        margin-bottom: 28px !important;
    }

    #location-card #location-content .header .text-container .address {
        line-height: 24px;
        color: var(--cg-grey-1000);
    }

    #btn {
        display: none !important;
    }

    .custom-map-iframe {
        @include m-global.size(560px, 419px, true);
        border: none;
    }

    .info-content-styled {
        align-self: stretch;
        @include m-global.font(14px, 400, 24px, 0.014px, var(--cg-grey-1000), null, true);
    }

    #location-card #location-content {
        @include m-global.padding-y(60px, false, true);
        @include m-global.padding-x(20px, false, true);
    }

    #location-content .header {
        display: flex;
        flex-direction: column !important;
        align-items: center;
        justify-content: flex-start;
        width: 100%;
        max-width: 1200px;
        margin: 0 auto;
        gap: 40px;
    }

    #location-content .header .text-container {
        @include m-global.flex(column, flex-start, true);
        flex: 1 1 0%;
    }

    #location-content .header .text-container {
        order: 1;
        @include m-global.container-fluid(770px, true);
        text-align: left;
        margin: 0 auto;

        .hero-txt {
            @include m-global.font(32px, 500, 36px, 0.48px, null, null, true);
            font-family: Roboto, sans-serif;
            display: block;
        }

        .hero-content {
            @include m-global.font(14px, 400, 24px, 0.014px, null, null, true);
            font-family: Roboto, sans-serif;
            display: block;
        }
    }

    #location-content .map-container {
        order: 2;
        @include m-global.container-fluid(770px, true);
        margin: 0 auto;
    }


    #location-content .btn-container-mobile {
        @include m-global.flex(flex-start, left, row, true);
        @include m-global.container-fluid(770px, true);
        margin: 0 auto;
    }

    #location-content .btn-container-mobile .btn-mobile-override {
        @include m-global.flex(center, center, row, true);
        @include m-global.size(248px, 52px, true);
        text-align: center;
        @include m-global.font(16px, 500, 20px, 0.04px, null, null, true);
        font-family: Roboto, sans-serif;

        &[button-style*="primary"] {
            background-color: var(--cx-secondary-700);
            color: var(--cg-grey-100);
        }

        &[button-style*="secondary"] {
            background-color: var(--cx-primary-300);
            color: var(--cx-secondary-700);
        }
    }
}

@media (max-width: 560px) {
    .custom-map-modal-content {
        @include m-global.size(336px, 312px, true);
        max-width: 336px;
        max-height: 312px;
    }

    .custom-map-iframe {
        @include m-global.size(320px, 240px, true);
        border: none;
    }

    #location-content .btn-container-mobile {
        @include m-global.flex(center, center, column, true);
        gap: 20px;
    }

    .custom-section {
        @include m-global.container-fluid(100%, true);
        margin: 0 auto;
        padding: 40px 0;
    }

    .custom-locations-section .custom-header {
        @include m-global.padding-y(40px, false, true);
    }

    #location-content .btn-container-mobile .btn-mobile-override {
        @include m-global.size(90%, auto, true);
        max-width: 400px;
        min-width: 330px;
        font-size: 14px;
        text-align: center;
    }

    #location-content .btn-container-mobile .btn-mobile-override .secondary,

    #location-content .btn-container-mobile .btn-mobile-override .primary-style {
        @include m-global.size(100%, auto, true);
        max-width: 400px;
        min-width: 330px;
        @include m-global.padding-x(20px, false, true);
        padding-top: 10px;
        padding-bottom: 10px;
        margin: 0 auto;
    }

    #location-card #location-content {
        @include m-global.padding-y(40px, false, true);
        @include m-global.padding-x(20px, false, true);
        padding-top: 40px !important;
    }
}

@media (max-width: 366px) {
    #location-content .btn-container-mobile {
        @include m-global.flex(center, center, column, true);
        gap: 10px;
    }

    #location-content .btn-container-mobile .btn-mobile-override {
        @include m-global.size(90%, auto, true);
        max-width: 400px;
        min-width: 300px;
        font-size: 14px;
        text-align: center;
    }

    #location-content .btn-container-mobile .btn-mobile-override .secondary,

    #location-content .btn-container-mobile .btn-mobile-override .primary-style {
        @include m-global.size(100%, auto, true);
        max-width: 400px;
        min-width: 300px;
        @include m-global.padding-x(20px, false, true);
        padding-top: 10px;
        padding-bottom: 10px;
        margin: 0 auto;
    }
}

.custom-card-arrow {
    @include m-global.absoluteOffset(20px, true, true);
    top: 50%;
    transform: translateY(-50%);
    @include m-global.size(24px, 24px);
    @include m-global.flex(center, center, row, true);
}

.custom-arrow-icon {
    @include m-global.size(16px, 16px);
    padding: 2px 4px;
    @include m-global.flex(center, center, row, true);
    gap: 10px;
    margin-left: 16px;
}

.arrow-icon {
    --md-icon-size: 12px;
    color: black !important;
}

.custom-section {
    background-color: var(--cg-grey-100);
    @include m-global.padding-y(80px, false, true);
    gap: 20px;

    .custom-container {
        @include m-global.margin-x(180px);
        max-width: calc(100% - 360px);
    }

    .custom-header {
        margin: 0 auto;
        max-width: 1550px;
        display: flex;
        flex-direction: column;
        width: 100%;

        .custom-title {
            @include m-global.font(40px, 700, 48px, 1.6px, var(--cx-secondary-600), null, true);
            font-family: Roboto, sans-serif;
            margin-bottom: 12px;
        }

        .custom-description {
            @include m-global.font(16px, 400, 32px, 0.24px, var(--cg-grey-1000), null, true);
            font-family: Roboto, sans-serif;
            margin-bottom: 40px;
        }
    }

    .custom-cards {
        @include m-global.grid(true, 2, 770px);
        grid-auto-rows: 72px;
        gap: 20px;
        justify-content: center;
        margin: 0 auto;
        max-width: calc(100% - 360px);
    }
}


@media (min-width: 10000px) {
    .locations-list .custom-location-card,

    .custom-cards .custom-card {
        @include m-global.size(770px, 176px, true);
    }

    .custom-cards {
        @include m-global.flex(center, flex-start, row, true);
        flex-wrap: wrap;
        gap: 20px;
    }
}

@media (max-width: 1142px) {
    .custom-container {
        margin: 0 auto;
        @include m-global.container-fluid(calc(100% - 40px), true);
        @include m-global.flex(column, flex-start, row, true);
        @include m-global.flex-gap(20px, flex-start, flex-start, column);
    }

    .custom-section {
        margin: 0 auto;
        @include m-global.padding-y(60px, false, true);
        max-width: 100%;
    }

    .custom-section .custom-header {
        @include m-global.flex(column, flex-start, row, true);
        @include m-global.flex-gap(10px, flex-start, flex-start, column);
        @include m-global.container-fluid(770px, true);
        text-align: left;
        padding: 0 20px;
        gap: 0px;
        margin: 0 auto;
    }

    .custom-section .custom-header .custom-title {
        @include m-global.font(32px, 500, 36px, 0.48px, null, null, true);
        text-align: left;
    }

    .custom-section .custom-header .custom-description {
        @include m-global.font(14px, 400, 20px, null, null, null, true);
        text-align: left;
    }

    .custom-section .custom-cards {
        @include m-global.grid(true, auto-fit, minmax(500px, 1fr));
        @include m-global.flex-gap(20px, center, center, column);
        margin: 0 auto;
        padding: 0 20px;
        @include m-global.container-fluid(770px, true);
    }

    .custom-section .custom-cards .custom-card {
        @include m-global.size(100%, auto, true);
        @include m-global.container-fluid(770px, true);
        height: auto;
        position: relative;
    }

    .custom-section .custom-cards .custom-card .custom-description {
        padding-right: 20px;
    }

    .custom-section .custom-cards .custom-card .custom-card-arrow {
        @include m-global.flex(center, center, row, true);
        display: flex !important;
    }
}

@media (max-width: 1600px) and (min-width: 1142px) {
    .custom-section {
        @include m-global.flex(column, center, row, true);
        @include m-global.flex-gap(40px, center, center, column);
        @include m-global.padding-y(80px, false, true);

        .custom-header {
            @include m-global.flex(column, flex-start, row, true);
            @include m-global.flex-gap(10px, flex-start, flex-start, column);
            @include m-global.container-fluid(770px, true);
            width: 100%;
            margin: 0 auto;
        }

        .custom-title {
            @include m-global.font(40px, 700, 48px, 1.6px, null, null, true);
        }

        .custom-description {
            @include m-global.font(16px, 400, 32px, 0.24px, null, null, true);
        }

        .custom-cards {
            @include m-global.flex(column, center, row, true);
            @include m-global.flex-gap(20px, center, center, column);
            @include m-global.container-fluid(770px, true);
            margin: 0 auto;

            .custom-card {
                @include m-global.size(770px, auto, true);
                margin: 0 auto;
            }
        }
    }
}

@media (max-width: 560px) {
    .custom-section {
        @include m-global.margin-x(auto);
        @include m-global.padding-y(40px, false, true);
        @include m-global.container-fluid(100%, true);
    }
}

@media (max-width: 768px) {
    #location-card #page-content-image img {
        height: 422px !important;
    }
}