@use 'styles/variables' as var;

#cx-input-search {
    .cx-form-control:not(.mdc-text-field--disabled):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline {
        &__leading,
        &__notch,
        &__trailing {
            color: var.$cx-purple;
        }
    }

    .mdc-notched-outline__leading {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    .mdc-notched-outline__trailing {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon--leading,
    .mdc-text-field__input::placeholder {
        color: var.$cx-purple;
    }

    .cx-form-control {
        border: 0;
        border-radius: 3px;
        background-color: var.$search-input-background !important;
        box-shadow: none;
    }

    .close-icon {
        z-index: 2000;
        top: calc(50% - 8px);
        right: 15px;
        color: var.$cx-purple;
        font-size: var.$largeplusplus;
        cursor: pointer;
    }

    .mdc-text-field__input::placeholder {
        color: var.$black;
    }

    .mdc-text-field__input::-ms-input-placeholder {
        color: var.$black;
    }
}
