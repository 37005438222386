@use '@chicksgroup/styles/mixins' as m;
@use '@chicksgroup/styles/variables' as v;

#cx-footer-menu {
    line-height: 15px;

    --cds-text-field-width: 100%;
    --cds-menu-width: 252px;
    --cds-menu-max-height: 428px;
    --cds-menu-padding: 0px;
    --cds-menu-scrollbar-thumb-color: var(--cx-secondary-700);
    --cds-text-field-trailing-icon-size: 20px;
    --cds-text-field-floating-label-color: var(--cg-grey-700);
    --cds-text-field-hover-floating-label-color: var(--cg-grey-700);
    --cds-text-field-focus-floating-label-color: var(--cg-grey-700);
    --cds-text-field-leading-icon-size: 24px;
    --cds-text-field-focus-placeholder-color: var(--cg-grey-600);
    --cds-menu-container-border: 1px solid var(--cg-grey-400);
    --cds-input-background: var(--cg-grey-100);
    --cds-option-box-padding-block: 0;
    --cds-option-box-padding-inline: 16px;
    --cds-option-box-height: 52px;
    --cds-option-box-hover-color: black;
    --cds-option-box-hover-opacity: 0.04;
    --cds-option-box-pressed-color: black;
    --cds-option-box-pressed-opacity: 0.0476;
    --cds-icon-color: var(--cg-grey-700);

    --md-menu-container-elevation: 0;
    --md-menu-container-shadow-color: transparent;
    --md-menu-container-color: var(--cg-grey-100);
    --md-menu-item-top-space: 0;
    --md-menu-item-bottom-space: 0;
    --md-menu-item-leading-space: 0;
    --md-menu-item-trailing-space: 0;
    --md-filled-text-field-focus-caret-color: var(--cg-grey-700);
    --md-filled-text-field-focus-label-text-color: var(--cg-grey-700);
    --md-filled-text-field-with-leading-icon-leading-space: 16px;
    --md-filled-text-field-icon-input-space: 8px;
    --md-filled-text-field-hover-state-layer-color: transparent;
    --md-menu-item-label-text-color: var(--cg-grey-700);
    --md-menu-item-one-line-container-height: 52px;

    a.menu-anchor {
        @include m.flex-gap(v.$thinborder, center, center);
        margin-top: 0;
        cursor: pointer;
        position: relative;
        font-size: 14px;
        font-family: v.$primaryfont;
        font-weight: 300;
        --md-icon-size: 16px;

        @media (width < v.$tablet) {
            --md-icon-size: 16px;
            width: 100%;
            @include m.flex(center, space-between);
        }

        cds-icon {
            padding: 2px 4px;

            &.active {
                animation: rotate-180deg .3s ease 0s 1 normal forwards;
            }

            &.inactive {
                rotate: 180deg;
                animation: rotate-back .3s ease 0s 1 normal forwards;
            }
        }
    }

    cds-menu md-menu-item a.menu-item-anchor {
        margin-bottom: 0 !important;
        font-weight: 400 !important;
        width: 252px !important;
        display: -webkit-box !important;
        color: var(--cg-grey-800);

        &:hover {
            text-decoration: none !important;
        }
    }

    md-menu-item:first-of-type.menu-items-filter {
        --cds-option-box-height: 56px;
        --md-menu-item-top-space: 16px;
        --md-menu-item-bottom-space: 16px;
        --md-menu-item-hover-state-layer-color: transparent;
        --md-menu-item-pressed-state-layer-color: transparent;
        --cds-option-box-hover-color: transparent;
        --cds-option-box-pressed-color: transparent;
    }

    cds-text-field {
        --cds-input-background: var(--cg-grey-250);
        --cds-input-hover-background-color: var(--cg-grey-300);
        --cds-input-focus-background-color: var(--cg-grey-350);
        border-radius: v.$thinborder;

        &:hover {
            outline: 1px solid var(--cx-secondary-700);
        }

        &:focus {
            outline: 2px solid var(--cx-secondary-700);
        }
    }

    #cx-no-results {
        --md-menu-item-hover-state-layer-color: transparent;
        padding: 0 16px 16px;
        cursor: auto;

        img {
            @include m.square(32px);
        }

        span {
            font: 300 14px/16px v.$primaryfont;
            letter-spacing: 0px;
        }
    }
}
