@use '@chicksgroup/styles/variables' as v;

#cx-footer-section {
    color: #F0F0F0;
    text-align: left;
    font-size: v.$largeplus;
    min-width: 98px;

    span {
        color: var(--cg-grey-900);
        font-weight: 500;
        width: fit-content;
        display: block;
        font-size: v.$largeplus;
        line-height: normal;

        @media (width <= v.$tablet) {
            font-size: v.$largeplus;
            letter-spacing: 0.448px;
        }
    }

    .links {
        margin-top: 12px;

        @media (min-width: v.$tablet) {
            margin-top: 17px;
        }

        @media (min-width: v.$desktop) {
            margin-top: 20px;
        }

        a {
            display: block;
            color: var(--cg-grey-700);
            margin-bottom: v.$medium;
            width: fit-content;
            line-height: v.$medium;
            white-space: nowrap;

            @media (min-width: v.$tablet) {
                font-size: v.$regular;
                font-weight: 300;
            }

            @media (min-width: v.$desktop) {

                &:hover {
                    text-decoration: underline !important;
                }
            }
        }
    }
}
