@use '@chicksgroup/styles/variables' as v;
@use '@chicksgroup/styles/mixins' as m;

#cx-language-currency-selector {
    --md-sys-color-on-surface: transparent;

    --cds-menu-width: 400px;
    --cds-menu-padding: v.$default-grid-spacing;
    --cds-menu-overflow: visible;
    --cds-menu-container-border: 1px solid var(--cg-grey-400);
    --cds-menu-scrollbar-thumb-color: var(--cx-secondary-700);

    --cds-option-box-padding-inline: 16px;
    --cds-option-box-padding-block: 8px;
    --cds-option-box-color: var(--cg-grey-100);

    --cds-input-background: var(--cg-grey-250);
    --cds-input-hover-background-color: var(--cg-grey-300);
    --cds-input-focus-background-color: var(--cg-grey-350);

    --cds-text-field-trailing-icon-size: 16px;
    --cds-text-field-floating-label-color: var(--cg-grey-700);
    --cds-text-field-focus-floating-label-color: var(--cg-grey-700);
    --cds-text-field-leading-icon-size: 24px;
    --cds-text-field-leading-icon-end-margin: 8px;

    --cds-autocomplete-max-height: 400px;
    --cds-autocomplete-color: var(--cg-grey-100);
    --cds-autocomplete-border-color: var(--cg-grey-400);
    --cds-autocomplete-space: 4px;
    --cds-autocomplete-divider-space: 0;
    --cds-autocomplete-shadow-color: transparent;
    --cds-autocomplete-item-min-width: 252px;
    --cds-autocomplete-item-label-max-width: 188px;
    --cds-autocomplete-item-label-color: var(--cg-grey-800);
    --cds-autocomplete-item-leading-gap: 8px;
    --cds-autocomplete-item-font-size: v.$regular;
    --cds-autocomplete-item-selected-color: var(--cg-grey-300);
    --cds-autocomplete-item-hover-color: black;
    --cds-autocomplete-item-hover-opacity: 0.04;
    --cds-autocomplete-item-pressed-color: black;
    --cds-autocomplete-item-pressed-opacity: 0.0476;
    --cds-autocomplete-item-disabled-label-color: var(--cg-grey-800);
    --cds-autocomplete-item-disabled-bottom-space: 8px;
    --cds-autocomplete-item-disabled-opacity: 1;
    --cds-autocomplete-item-disabled-font-size: 12px;
    --cds-autocomplete-item-disabled-line-height: 16px;
    --cds-autocomplete-item-disabled-letter-spacing: 0.24px;
    --cds-autocomplete-item-disabled-font-weight: 400;
    --cds-autocomplete-item-disabled-height: 24px;
    --cds-autocomplete-menu-min-width: 252px;

    --md-menu-container-color: var(--cg-grey-100);
    --md-menu-item-pressed-state-layer-opacity: 0;
    --md-filled-text-field-input-text-color: var(--cg-grey-900);
    --md-filled-text-field-hover-input-text-color: var(--cg-grey-900);
    --md-filled-text-field-focus-input-text-color: var(--cg-grey-900);
    --md-filled-text-field-label-text-color: var(--cg-grey-700);
    --md-filled-text-field-hover-label-text-color: var(--cg-grey-700);
    --md-filled-text-field-focus-label-text-color: var(--cg-grey-700);
    --md-filled-text-field-leading-icon-color: var(--cx-secondary-700);
    --md-filled-text-field-hover-leading-icon-color: var(--cx-secondary-700);
    --md-filled-text-field-focus-leading-icon-color: var(--cx-secondary-700);
    --md-filled-text-field-trailing-icon-color: var(--cg-grey-900);
    --md-menu-container-shadow-color: transparent;

    border-radius: 4px;
    margin-top: v.$extralargeplus;
    width: fit-content;

    a.menu-anchor {
        @include m.default-font();
        @include m.font(14px, 300, normal, null, var(--cg-grey-700));
        @include m.flex-gap(4px, center, space-between, $direction: row);
        cursor: pointer;

        .lng-icon {
            --cds-icon-color: var(--cx-secondary-700);
            --md-icon-size: 20px;
        }

        &:hover {
            span {
                text-decoration: underline;
            }
        }

        .menu-icon {
            --cds-icon-color: var(--cg-grey-700);
            --md-icon-size: 16px;
            padding: 2px 4px;

            &.active {
                animation: rotate-180deg .3s ease 0s 1 normal forwards;
            }

            &.inactive {
                rotate: 180deg;
                animation: rotate-back .3s ease 0s 1 normal forwards;
            }
        }
    }

    .language-menu {
        margin-right: v.$medium;
    }

    cds-menu {
        display: flex;
        height: v.$largeplus;
    }

    cds-autocomplete {
        border-radius: v.$thinborder;

        &:hover {
            outline: 1px solid var(--cx-secondary-700);
            background-color: var(--cg-grey-300);
        }

        &:focus {
            outline: 2px solid var(--cx-secondary-700);
            background-color: var(--cg-grey-300);
        }
    }

    #cx-no-results {
        padding: v.$largeplus v.$medium;

        span {
            font-size: v.$regular;
        }

        img {
            @include m.square(32px);
        }
    }

    .option-box {
        @include m.flex(center, center, row);
        padding: v.$extra-tiny v.$medium;
    }
}
