@use 'styles/_variables.scss' as var;

#cx-line {
    .purple-line {
        height: 4px;
        background: var.$purple-text;
        border-radius: 4px;
    }

    .line {
        width: 60px;
        transition: 0.3s;
        margin-top: 16px;
        margin-bottom: 30px;
    }

    @media (max-width: var.$desktop) {
        margin-bottom: var.$regularPad !important;
    }

    .gray {
        width: 53px;
        height: 5px;
        border-radius: 4px;
        background: var.$handlebar-gray;
        margin: 0 !important;
    }

    .cx-line {
        &--thin {
            height: 1px;
        }

        &--regular-plus {
            height: 5px;
        }
        
        &--full {
            width: 100% !important;
        }
    }
}
