@import 'styles/variables';

#cx-exchange-pair {
    height: 50px;
    width: 294px;
    padding-left: 40px;
    padding-right: 40px;
    background: $white;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.12);
    border-radius: 14px;
    margin-bottom: 30px;

    &:hover {
        outline: 1px solid $purple-text;
    }

    &:active {
        outline: 2px solid $purple-text;
    }

    .exchange-pair {
        & .icon-pair {
            max-width: 30px;
            max-height: 24px;
        }
    }
}
