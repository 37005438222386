@import 'styles/variables';
@import 'styles/mixins';

#cx-language-slider {
    column-gap: 20px;

    .language-icon {
        @include square(28px);
        border-radius: 100%;
        object-fit: cover;
    }

    .language-container {
        background: rgba(247, 247, 247, 1);
        color: $gray;
        width: 136px;
        height: 44px !important;
        border-radius: $thinborder;
        color: $gray-blue;
        border: 1px solid transparent;
        transition: all .3s ease-in-out;
        box-sizing: border-box;
        font-size: $small;

        &:hover {
            background: $secondarycolor;
            color: $purple-text;
        }
        
        &:active {
            background: $secondarycolor-pressed;
            color: $purple-text;
        }
        &:focus, &:focus-within {
            outline: none;
            border: 1px solid $cx-purple;
            background: $secondarycolor-pressed;
            color: $purple-text;
        }
    }

    .active-language {
        background: $secondarycolor-pressed;
        color: $purple-text;
        &:hover {
            background: $secondarycolor-pressed;
        }
        &.active-border {
            border: 1px solid $cx-purple;
        }
    }

    .glider-track {
        column-gap: $small;
    }

    .glider-language-prev,
    .glider-language-next {
        top: 8px;
        font-size: $extralargeplusplus;
        color: $purple-text;
    }

    .glider-language-prev {
        left: -10px;
    }

    .glider-language-next {
        right: 16px;
    }

    .glider-language {
        max-width: calc(100% - 48px);
        margin: 0;
        
        .glider-slide {
            min-width: 136px;
        }
    }
    
    @include media($extralargescreen, false, 1px) {
        .glider-language {
            max-width: 97%;
        }
    }
}
