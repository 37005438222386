@import 'styles/variables';

.cx-faq-question {
    background-color: $white;
    color: $black;
    border-radius: 12px;
    cursor: pointer;
    height: 144px;
    padding: 20px;
    margin-bottom: 20px;
    transition: all .5s ease;
    width: 294px;

    @media (width <= $desktop) {
        width: 728px;
    }

    @media (width <= $phone) {
        width: 335px;
    }

    &:hover {
        outline: 1px solid $purplehover;
    }

    &.active {
        outline: 1px solid $purple-text;
        height: fit-content;
    }

    .header-expandable {
        display: flex;
        justify-content: space-between;
    }

    .question {
        line-height: 26px;
        letter-spacing: 0.448px;
    }

    .answer {
        line-height: 28px;
        letter-spacing: 0.512px;
        margin-bottom: 0 !important;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $black;

        & div {
            float: right;
        }
    }

    .answer-close {
        max-height: 60px;
        transition: 0.01s ease;
        text-overflow: ellipsis;
        overflow: hidden;
        position: relative;
    }

    .answer-close:after {
        position: absolute;
        bottom: 0;
        height: 100%;
        width: 100%;
        content: "";
        background: linear-gradient(to top,
           rgba(255,255,255, 1) 20%, 
           rgba(255,255,255, 0) 80%
        );
        pointer-events: none;
    }

    .answer-open {
        max-height: 600px;
        transition: 0.5s ease;
    }

    &.highlight-me {
        animation: highlight ease-in-out 1s 2 alternate;
    }

    @keyframes highlight {
        0% {
            background-color: $white;
        }

        100% {
            background-color: rgb(49, 15, 162, 0.2);
        }
    }

    .text-regular {
        color: $softblack;
    }

    .text-truncate-width {
        max-width: 90%;
    }

    .text-truncate-active {
        word-break: break-word;
    }
}
