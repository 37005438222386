@use 'styles/variables' as var;
@use 'styles/mixins' as mix;

#cx-learn-more-card {
    background-color: var.$inputgray;
    border-radius: var.$small;
    width: 372px;
    height: 363px;
    padding: 40px 42px 40px 40px;

    @media (width <= var.$widescreen) {
        width: 100%;
        height: unset;
        padding: var.$extralarge 60px;
    }

    @media (width <= var.$desktop) {
        padding: var.$morehuge;
    }

    @media (width <= var.$phone) {
        padding: var.$largeplus;
    }

    .learn-more-icon {
        @include mix.square(79.01px);

        @media (width <= var.$desktop) {
            @include mix.square(var.$extrahugeplustitle);
        }

        @media (width <= var.$phone) {
            @include mix.square(var.$extrahugetitle);
        }

    }

    .learn-more-title {
        height: 72px;
        font-size: var.$huge;
        line-height: var.$extrahuge;
        -webkit-line-clamp: 2;

        & p {
            margin: 0;
            color: var.$black;
        }

        @media (width <= var.$widescreen) {
            height: auto;
        }

        @media (width <= var.$desktop) {
            margin-top: 0;
            -webkit-line-clamp: unset;
            font-size: var.$huge;
            font-style: normal;
            font-weight: 500;
        }

        @media (width <= var.$phone) {
            font-size: var.$medium;
            line-height: 20px;
            margin-top: 0;
        }
    }

    .learn-more-paragraph {
        margin: var.$largeplus 0;
        font-size: var.$medium;
        line-height: var.$huge;
        -webkit-line-clamp: 3;
        color: var.$darkergray;

        & p {
            margin: 0;
        }

        @media (width <= var.$desktop) {
            height: unset;
            -webkit-line-clamp: unset;
        }

        @media (width <= var.$phone) {
            font-size: var.$medium !important;
            line-height: var.$huge;
        }

        &.skeleton-loading {
            width: 290px;
            height: 84px;

            @media (width <= var.$desktop) {
                width: 100%;
                height: 56px;
            }
        }
    }

    .lines-overflow {
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden !important;
        text-overflow: ellipsis;
    }

    a {
        max-width: fit-content;
    }

    .learn-more-button {
        --cds-button-height: 52px;
        --cds-button-width: 152px;
        --md-filled-button-container-shape: 4px;
        --md-filled-button-container-color: rgba(49, 15, 162, 1);
        --md-filled-button-label-text-size: #{var.$medium};
        --md-filled-button-label-text-line-height: #{var.$largeplus};
    }

    .cx-learn-more-header {
        display: flex;
        flex-direction: column;

        @media (width <= var.$desktop) {
            flex-direction: row;
            align-items: center;
            gap: var.$regularplus;
        }

        @media (width <= var.$desktop) {
            gap: var.$tiny;
        }

        &.skeleton-loading {
            @media (width <= var.$desktop) {
                width: 460px;
                height: 32px;
            }

            @media (width <= var.$phone) {
                width: 290px;
                height: 67px;
            }
        }
    }

    .container-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
    }
}
