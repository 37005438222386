@import 'styles/variables';

.cx-category-card {
    background-color: $white;
    box-shadow: 0 1px 1px 0 $faq-card-shadow;
    color: $black;
    cursor: pointer;
    border-radius: 3px;
    height: 168px;
    display: block;

    @media (width <= $phone) {
        width: 335px;
    }

    .icon-background {
        height: 70px;
        width: 70px;
        border-radius: 50%;
        background: rgba(12, 42, 146, 0.2);
    }

    .category-card-image {
        max-width: 35px !important;
        max-height: 35px !important;
        filter: brightness(0) saturate(100%) invert(16%) sepia(33%) saturate(6426%) hue-rotate(248deg) brightness(87%) contrast(124%);
    }

    .category-card-icon {
        font-size: 36px;
        color: $purple-text;

        @media (max-width: $tablet) {
            margin-top: $small;
        }

        @media (max-width: $phone) {
            margin-top: $regular;
        }
    }

    .icon-container {
        margin-bottom: 10px;
    }

    &:hover {
        outline: 1px solid $purple-text;

        +.bottom-border {
            outline: 1px solid $purple-text;
        }
    }

    &.active {
        background: $cx-purple-gradient;
        color: $white;
    
        .icon-background {
            background-color: $white;
        }
    
        .circle {
            transform: rotate(90deg);
        }

        .category-card-image {
            filter: brightness(0) saturate(100%) invert(94%) sepia(0%) saturate(25%) hue-rotate(13deg) brightness(105%) contrast(106%);
        }
    }
}
