@use '@material/textfield';
@use 'styles/mixins' as m;
@import 'styles/variables';

#cx-form-get-started {
    z-index: 1;
    --md-icon-size: 24px;

    .get-started-form {
        display: flex;
        flex-direction: column;
        gap: $bigPad;

        @media (width < $desktop) {
            gap: $regularPad;
        }

        .exchange-type-section {
            @media (width >=$desktop) {
                width: 100%;
            }

            @media (width < $desktop) {
                width: 60%;
            }
        }

        #cx-select {
            @media (width < $desktop) {
                width: 335px;
            }

            @media (width < $phone) {
                width: unset;
            }
        }
    }

    .invert-currency {
        --cds-icon-color: var(--cg-grey-900);
        --md-icon-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        transform: rotate(90deg);
        width: 40px;
        height: 40px;

        @media (width >=$phone) {
            transform: none;
        }
    }


    .exchange-grid {
        @include m.flex-column(center);

        @media (width >=$phone) {
            display: flex;
            flex-direction: row;
            gap: 16px;
            margin-bottom: $extra-tiny;
        }
    }

    .you-give,
    .you-receive {
        width: 100%;
        max-width: 442px;
        height: 76px;

        .skeleton-loading {
            display: block !important;
        }

        @media (width <=$desktop) {
            height: 52px;
            max-width: unset;
        }
    }

    cx-amount-payment-selector {
        height: 76px;

        @media (width <=$desktop) {
            height: 52px;
        }

        .payment-method-selector {
            height: 52px;
            max-height: 52px;

            @media (width <=$desktop) {
                height: 52px;
                max-height: 52px;
                max-width: unset;

                .mdc-text-field--filled {
                    height: 52px;
                }
            }
        }
    }

    .total {
        margin-top: 24px;

        @media (width <=$phone) {
            margin-top: $regularPad;
        }
    }

    .summary-rates {
        font-size: $medium;

        @media (width <=$tablet) {
            font-size: $regular;
            font-weight: 400;
        }

        @media (width <=$phone) {
            font-weight: 700;
            position: absolute;
            width: 100%;
            justify-content: center !important;
            margin-top: 3px;
        }
    }

    .summary-total {
        font-size: $medium;

        @media (width <=$tablet) {
            font-size: $regular;
            font-weight: 400;
        }

        @media (width <=$phone) {
            font-weight: 700;
            position: absolute;
            margin-top: 37px;
            width: 290px !important;
            justify-content: center;

        }
    }

    .icon {
        border: 1px solid var(--cg-grey-400);
        border-radius: 50%;
        padding: 10.5px 9.5px;
        max-width: 40px;
        max-height: 40px;

        &:hover {
            background-color: $semi-light-gray-border;
        }

        &:active {
            background-color: $light-gray-border;
        }

        @media (width <=$phone) {
            max-width: 40px;
            max-height: 40px;
        }
    }

    .mdc-text-field--outlined {
        padding-left: 14px;
    }

    .exchange-type-selector {
        position: relative;
        min-width: 380px;

        @media (width <=$phone) {
            border-right: 0 !important;
            padding-right: 0 !important;
            gap: 28px !important;
        }

        @media (width <=$desktop) {
            border-right: 1px solid var(--cg-grey-400);
            min-width: unset;
            padding-right: $medium;
            display: flex;
            gap: $regularPad;
            flex-direction: column;
        }
    }

    .button-group {
        margin: 0;
        display: flex;
        border: solid 1px var(--cg-grey-400);
        background-color: $backgroundcontrol;
        border-radius: 35px;
        padding: 1px;

        @media (width >=$phone) {
            max-width: 380px;
        }

        button {
            z-index: 1;
            padding: 14px $regularPad;
            border: none;
            border-radius: 31px;
            background-color: transparent;
            font-family: $primaryfont;
            font-size: $regular;
            line-height: 16px;
            color: $black;
            font-weight: 400;

            &.button-hover:hover:not(.active) {
                background-color: $secondarycolor-half;
            }

            &.active {
                color: $white;
            }
        }
    }

    .button-animated {
        position: absolute;
        top: 1%;
        background-color: $purple-text;
        width: 125px;
        height: 48px;
        padding: 16px $regularPad;
        border: none;
        border-radius: 31px;
        font-family: $primaryfont;
        font-size: $regular;
        line-height: 16px;
        text-align: center;
        font-weight: 500;
    }

    .exchange-sub-type-selector {
        display: flex;
        gap: 4px;

        cds-button.btn-subselector {
            font-weight: 500;
            font-size: 14px;

            --md-filled-tonal-button-container-color: rgba(247, 247, 247, 1);
            --md-filled-tonal-button-label-text-color: rgba(31, 31, 31, 1);
            --md-filled-tonal-button-hover-label-text-color: rgba(31, 31, 31, 1);
            --md-filled-tonal-button-focus-label-text-color: rgba(31, 31, 31, 1);
            --md-filled-tonal-button-pressed-label-text-color: rgba(31, 31, 31, 1);
            --md-filled-tonal-button-hover-container-elevation: 0;
            --md-filled-tonal-button-pressed-container-elevation: 0;
            --md-filled-tonal-button-pressed-state-layer-color: transparent;
            --md-filled-tonal-button-label-text-font: "Roboto", sans-serif;
            --md-filled-tonal-button-label-text-weight: 400;

            &.active {
                --md-filled-tonal-button-container-color: rgba(221, 221, 255, 1);
                --md-filled-tonal-button-label-text-color: rgba(49, 15, 162, 1);
                --md-filled-tonal-button-hover-label-text-color: rgba(49, 15, 162, 1);
                --md-filled-tonal-button-focus-label-text-color: rgba(49, 15, 162, 1);
                --md-filled-tonal-button-pressed-label-text-color: rgba(49, 15, 162, 1);
                --md-filled-tonal-button-label-text-weight: 500;
            }
        }
    }

    .icon-summary {
        max-width: 17px;
        max-height: 17px;
        margin-bottom: 2px;

        @media (width <=$phone) {
            max-width: 16px;
            max-height: 16px;
        }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .currency-flag {
        width: 24px;
        height: 13px;
    }

    .crypto-icon {
        width: 24px;
        height: 24px;
    }

    .crypto-dropdown .mdc-list-item {
        padding-left: 0px;
        padding-right: 0px;
    }

    .currency-dropdown .mdc-list-item {
        padding-left: 0px;
        padding-right: 0px;
    }

    .mdc-text-field__affix--prefix {
        color: $black;
    }

    .mdc-text-field__input {
        font-size: $regular;
        letter-spacing: 0.035px;

        @media (width <=$phone) {
            font-size: $regular;
        }
    }

    .mdc-floating-label {
        letter-spacing: 0.24px;
    }

    .amount-label {
        margin-bottom: 8px;

        @media (width <=$phone) {
            margin-top: $regularPad;
        }
    }

    .exchange-rate {
        align-self: center;
        margin-left: 6px;
        color: $gray-description;
        font-family: $primaryfont;
        font-size: $smallplus;
    }

    .timer-container {
        max-width: 100%;
        justify-content: center;
        margin: 15px 0;

        @media (width >=$phone) {
            justify-content: flex-start;
            margin: 37px $regularPad 0 0;
        }

        @media (width >=$desktop) {
            margin-bottom: $regularPad;
        }
    }

    .dropdown-menu {
        width: 100% !important;
        left: unset !important;
        right: 0;

        @media (width >=$phone) {
            width: 300px !important;
        }

        @media (width >=$desktop) {
            right: -14px !important;
        }
    }

    .exchange {
        height: 52px;
        width: fit-content;

        @media (width <=$desktop) {
            height: 50px;

            button {
                height: 50px;
            }
        }

        @media (width <=$phone) {
            margin: 15px 0;
        }
    }

    #cx-button {
        max-width: 152px;

        button {
            background: $purple-text !important;
        }
    }

    #cx-amount-payment-selector {
        max-width: 340px;

        @media (width <=$desktop) {
            max-width: unset;
        }

        #cx-payment-method-selector .expand-icon{
            --md-icon-size: 24px;
        }
    }

    .mdc-text-field__affix--prefix {
        font-size: $regular;
    }

    .skeleton {
        height: 19.5px;

        &.skeleton-exchange-rate {
            margin-left: 6px;
            width: 120px;
        }
    }

    @media (width <=$phone) {
        .text-medium {
            font-size: $medium;
        }

        .info-icon {
            display: none;
        }
    }

    cx-coupon-input {
        display: flex;
        width: fit-content;

        #cx-coupon-input {
            margin-left: 0;

            .mdc-text-field__input {
                color: var(--cg-grey-700);
            }

            @media (width < $desktop) {
                height: 26px;
                margin-top: 4px;
            }
        }

        @media (width >=$desktop) {
            height: 26px;
            margin-top: 0;
        }
    }

    .exchange-container {
        gap: $medium;

        @media (width <=$desktop) {
            display: grid;
            grid-template-columns: 3fr 2fr;
        }

        @media (width <=$phone) {
            display: flex;
            flex-direction: column;
            gap: 28px;
        }
    }

    .currency-selector-container {
        @include m.flex-gap($small, initial, center, column);
        margin-top: 30px;

        @media (width <=$phone) {
            margin: 0;
        }
    }

    .invert-currency-mobile {
        display: flex;
        align-items: center;
        gap: $extra-tiny;
        color: $purple-text;
        font-size: $regular;
        font-weight: 500;
        cursor: pointer;
        width: fit-content;

        .exchange-icon-mobile {
            --md-icon-size: 16px;
        }

        div {
            letter-spacing: 0.28px;
        }
    }

    .add-exchange-btn-fixed {
        position: fixed;
        bottom: 0;
        left: 0;
        padding: $regular;
        background-color: var(--cg-grey-100);
        width: 100%;

        #cx-button {
            max-width: unset;
        }
    }

    .exchange-input-container {
        gap: $extra-tiny;
        display: flex;
        flex-direction: column;
    }
}