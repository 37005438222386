@use 'styles/variables' as var;

#cx-hero {
    display: grid;
    grid-template-columns: repeat(1, 100%);
    padding-top: 80px;
    margin-bottom: 450px;
    height: 371px;

    @media (width >= var.$tablet) {
        padding-top: 100px;
        margin-bottom: 0;
        height: 345px;
    }

    @media (width >= var.$desktop) {
        grid-template-columns: repeat(2, 50%);
        padding-top: 146px;
    }

    .banner {
        color: var.$white;
        max-width: 673px;
        line-height: 62px !important;
        margin-bottom: 12px;

        .banner-subtitle {
            line-height: var.$extralarge;
            max-width: 361px;
            line-height: 18.75px;

            @media (width >= var.$desktop) {
                margin-bottom: var.$medium;
            }

            &.skeleton-loading {
                margin-bottom: 30px;

                @media (width <= var.$phone) {
                    width: 100%;
                    height: 38px;
                }
            }
        }

        .banner-title {
            margin-bottom: var.$regularPad;

            & h1 {
                font-size: var.$moreplushuge !important;
                font-weight: 600;
                line-height: 37.5px;

                @media (width >= var.$tablet) {
                    font-size: var.$extrahugeplustitle !important;
                    line-height: 58.59px;
                }

                @media (width >= var.$desktop) {
                    font-size: 58px !important;
                    font-weight: 600;
                    line-height: 47px;
                    letter-spacing: 0.0128em;
                }
            }

            @media (width >= var.$desktop) {
                margin-bottom: 30px;

                &.skeleton-loading {
                    width: 550px;
                    height: 48px;
                }
            }

            @media (width <= var.$desktop) {
                &.skeleton-loading {
                    height: 38px;
                    margin-bottom: 30px;
                }
            }
        }

        @media (width >= var.$tablet) {
            .banner-subtitle {
                max-width: 464px;

                &.skeleton-loading {
                    height: 38px;
                }
            }
        }

        @media (width >= var.$desktop) {
            line-height: 48px;
            margin-bottom: 33px;
        }
    }

    .exchange-btn {
        width: 170px;
        background-color: var.$lilac-btn !important;
        line-height: 19px;
        padding: 16px 32px;
        border-radius: var.$thinborder !important;
        margin-top: var.$thinborder;

        @media (width >= var.$desktop) {
            margin-top: 14px;
        }
    }

    .exchange-btn:hover {
        background-color: var.$white-hover !important;
        color: var.$cx-primary-hover !important;
    }

    .exchange-btn:active {
        background-color: var.$white !important;
        color: var.$cx-primary-hover !important;
    }

    .learn-more {
        color: var.$lilac-btn;
        line-height: 18.75px;
        max-width: max-content;
        width: max-content;

        & .material-icons {
            font-size: var.$largeplusplus;
        }

        &:hover {
            > span:first-child {
                text-decoration: underline !important;
            }
        }

        &.skeleton-loading {
            max-width: unset;
            width: 120px;
        }
    }

    .lh-huge {
        line-height: var.$huge;
    }

    .licenses-container {
        width: 285px;
        margin-bottom: 20px;

        @media (width >= var.$tablet) {
            margin-bottom: 0;
        }

        @media (width >= var.$desktop) {
            width: 322px;
        }

        @media (width <= var.$desktop) {
            width: unset;
            margin-right: 40px;
        }
    }

    .trustpilot-container {
        flex-grow: 1;
    }

    .licenses-container,
    .trustpilot-container {
        height: 56px;
    }

    .medal-image,
    .shield-image {
        max-width: 48px;
        max-height: 48px;

        &.skeleton-loading {
            width: 48px;
            height: 48px;
            margin-right: 5px;
        }
    }

    .licenses-text {
        width: 250px;

        & p {
            margin-bottom: 0;
            font-size: var.$medium;
        }

        &.skeleton-loading {
            height: 28px;
            width: 220px;
        }
    }

    .skeleton-loading {
        &.rating-stars {
            height: 28px;
            width: 120px;
        }

        &.rating-text {
            height: 28px;
            width: 270px;
        }
    }



    .trust-pilot-star {
        margin-bottom: 5px;
    }

    .trust-pilot-semisesqui-star {
        margin-bottom: 4px;
    }

    .trust-pilot-text {
        margin-left: 5px;
    }

    .text-medium {
        font-size: var.$medium !important;
    }
}
