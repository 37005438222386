@import 'styles/_variables.scss';

#cx-live-rates {
    background: transparent;
    border-radius: 12px;
    padding: 20px 0;
    height: 100%;
    margin: 80px 0 80px 0;

    @media (max-width: $desktop) {
        grid-template-columns: repeat(2, 50%);
        padding: 0;
        margin: 60px 0 60px 0;
    }

    @media (max-width: $phone) {
        grid-template-columns: repeat(1, 100%);
        margin-top: 40px;
    }

    .exchange-title {
        font-size: $moreplushuge;
        color: $black;
        width: 100%;
        margin-bottom: 15px;

        @media (width <= $desktop) {
            font-size: $extralarge;
            margin-bottom: 3px;
        }

        @media (max-width: $phone) {
            font-size: $huge;
            padding-left: 20px !important;
        }
    }

    table {
        border-collapse: collapse;
    }

    .container {
        width: 100vw;
        max-width: 1548px;

        @media (max-width: $extrascreen) {
            max-width: calc(100% - 40px);
        }

        @media (max-width: $phone) {
            max-width: 100%;
        }
    }

    th, td {
        text-align: left;
        white-space: nowrap;

        @media (max-width: $phone) {
            padding: 29px 10px 29px 10px;
        }
    }

    th {
        background-color: transparent !important;
        border-radius: 6px !important;
        height: 60px !important;
        padding: 17px 0 22px 20px;
        color: $darkergray;

        @media (width <= $desktop) {
            height: 50px;
            padding: 17px 0 0 20px;
            font-size: $regular !important;
        }
    }

    td {
        height: 70px !important;
        padding: 23px 0 23px 20px;

        @media (max-width: $tablet) {
            padding: 16px 0 16px 20px;
        }
    }

    tr {
        border-radius: 6px !important;
    }

    .chart {
        height: 10px;
        background-color: rgba(0, 174, 103, 1);
    }

    .header {
        background-color: transparent !important;
    }
    
    td:nth-child(3) {
        color:rgba(0, 174, 103, 1);
    }

    .content-table {
        .skeleton {
            width: 100px;
            height: 25px;
        }

        .hightlighted {
            background: $cx-blue-second-gradient !important;
            color: $white;      
        }

        .regular-even {
            background-color: transparent !important;
            color: $midgray;
        }

        .regular-odd {
            background-color: $odd-gray;
            color: $midgray;
        }

        td:first-child {
            border-top-left-radius: 6px !important;
            border-bottom-left-radius: 6px;

            @media (max-width: $phone) {
                border-top-left-radius: 0 !important;
                border-bottom-left-radius: 0;
            }
        }

        td:last-child {
            border-top-right-radius: 6px !important;
            border-bottom-right-radius: 6px;

            @media (max-width: $phone) {
                border-top-right-radius: 0 !important;
                border-bottom-right-radius: 0;
                padding-right: 5px;
            }
        }

        .currency-image {
            height: 24px;
            width: 24px;
            margin-right: 10px;
        }

        .upward-trend {
            color: rgba(39, 174, 96, 1);
        }
    
        .downward-trend {
            color: $red;
        }

        .pertentage {
            position: relative;
            top: -2.5px !important;
        }

        .material-icons {
            font-size: $medium !important;
        }
    }

    @media (max-width: $phone) {
        .text-medium {
            font-size: $regular;
        }
    }
}
