@use '@material/select/_index' as select;
@use "@material/linear-progress";
@include linear-progress.core-styles;
@import 'styles/variables';

#cx-file-input {
    height: 60px;

    @media (max-width: $desktop) {
        height: initial;
    }

    .cx-file-input--select {
        @include select.outline-color(('default': $transparent, 'hover': $purplehover, 'focus': $purple-text));
        @include select.outline-shape-radius($small);
        @include select.ink-color($input-placeholder);
        position: relative;
        max-width: 49.59%;
        margin-right: 20px;
        height: 50px;
       
        &:hover {
            @include select.ink-color($input-placeholder-hover);
        }

        &.mdc-select--focused {
            @include select.ink-color($black);
        }

        &:has(.mdc-menu-surface--open) {
            .arrow-icon-select-input-file {
                transform: rotate(180deg);
            }
        }

        .mdc-select__selected-text {
            font-size: $regular;
        }

        .mdc-menu-surface {
            margin-top: 3px;
        }

        @media (max-width: $desktop) {
            max-width: 100%;
            margin-right: 0;
        }
    }

    :not(.mdc-list-item--disabled).mdc-list-item.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before,
    :not(.mdc-list-item--disabled).mdc-list-item:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
        opacity: 0;
    }

    .mdc-menu {
        padding: 10px 0 10px 20px;
        min-height: 260px !important;
    }

    .mdc-list-item {
        background-color: $transparent;
        margin-right: 20px;
        border-bottom: 1px solid $secondaryborder;
        padding: 10px 13px;

        &:hover {
            background-color: $lilac-hover;
        }

        .mdc-list-item__primary-text {
            letter-spacing: 0.68px;
            font-weight: 400;
            color: rgba(33, 37, 41, 1);
            font-size: inherit;
            font-family: inherit;
        }
    }

    .input-password {
        border-radius: 12px;
    }

    .upload-icon {
        width: 16px;
        height: 19px;
        right: 18px;
        top: 14px;
    }

    #cx-file-input--input {
        max-width: 47.11%;

        .mdc-text-field__input {
            font-size: $regular;
        }

        @media (max-width: $desktop) {
            max-width: 100%;
            height: 60px;
        }
    }

    .linear-progress-file {
        margin-bottom: 5px;
        @include linear-progress.bar-color($green);

        @media (max-width: $desktop) {
            margin-bottom: 3px;
        }
    }
}