@use '@chicksgroup/styles/variables' as v;

#footer-dropdown {
    --md-icon-size: 16px;
    --cds-icon-color: var(--cg-grey-700);

    .dropdown-top {
        color: var(--cg-grey-900);
        padding: 15px 0;
        border-bottom: 1px solid var(--cg-grey-400);
        line-height: v.$medium;

        span {
            font-size: v.$regular;
            font-weight: 500;
            letter-spacing: 0.014px;
        }


        @media (width < v.$phone) {
            padding: 12px 0;
        }
    }

    .dropdown-content {
        max-height: 0;
        overflow: hidden;
        margin: 0;
        font-size: v.$regular;
        font-weight: 300;

        a {
            display: block;
            margin: 12px 0;
            color: var(--cg-grey-700);
            width: fit-content;

            &.Faq {
                text-transform: uppercase;
            }

            &:last-child {
                margin-bottom: 17px;
            }

            &:first {
                margin-top: 17px;
            }

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &.active {
        cds-icon.dropdown-top-icon {
            animation: rotate-180deg .3s ease 0s 1 normal forwards;
        }

        .dropdown-content {
            animation: showUp 1.2s ease 0s 1 normal forwards;
            border-bottom: 0px solid var(--cg-grey-400);

            @keyframes showUp {
                100% {
                    max-height: 1000px;
                    border-bottom: 1px solid var(--cg-grey-400);
                    overflow: visible;
                }
            }
        }
    }

    &.inactive {
        cds-icon.dropdown-top-icon {
            rotate: 180deg;
            animation: rotate-back .3s ease 0s 1 normal forwards;
        }

        .dropdown-content {
            max-height: 1000px;
            border-bottom: 1px solid v.$darkgray;
            animation: hide .3s ease 0s 1 normal forwards;

            @keyframes hide {
                100% {
                    max-height: 0;
                    border-bottom: 0px solid v.$darkgray;
                }
            }
        }
    }
}
