@use 'styles/variables' as var;
@use '@material/select';

#cx-filter {
    @include select.label-floating-color(var.$midgray);
    @include select.container-fill-color(rgba(246, 246, 253, 1));
    @include select.bottom-line-color((
        'default': var.$transparent,
        'hover': var.$transparent,
        'focus': var.$transparent,
        'disabled': var.$transparent
    ));
    @include select.outline-color(var.$transparent);

    .mdc-select:hover {
        @include select.container-fill-color(var.$secondarycolor);
    }

    .mdc-select__anchor {
        border-radius: 4px;

        &:hover,
        &.mdc-ripple-upgraded--background-focused,
        &:not(.mdc-ripple-upgraded):focus,
        &.mdc-ripple-surface--hover {
            &  .mdc-select__ripple::before {
                opacity: 0;
            }
        }
    }

    .mdc-list-item {
        &:hover {
            background-color: var.$secondarycolor;
        }

        &:focus {
            background-color: var.$secondarycolor-pressed;

            &:not(.mdc-list-item--disabled) {
                &.mdc-ripple-upgraded--background-focused .mdc-list-item__ripple::before,
                &:not(.mdc-ripple-upgraded):focus .mdc-list-item__ripple::before {
                    opacity: 0;
                }
            }
        }
    }

    .mdc-list-item__primary-text,
    .mdc-select__selected-text {
        color: var.$midgray;
        font-size: var.$regular;
        line-height: 16.41px;
        letter-spacing: 0.45px;
    }
}
